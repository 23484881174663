import React from 'react';
import styles from './disclaimer.module.css';

const Disclaimer = () => (
  <div className={styles.disclaimer}>
    <div className={styles.header}>
      <div className={styles.icon}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.51472 3.52253C5.76516 1.27209 8.8174 0.0078125 12 0.0078125C15.1826 0.0078125 18.2348 1.27209 20.4853 3.52253C22.7357 5.77297 24 8.82522 24 12.0078C24 15.1904 22.7357 18.2427 20.4853 20.4931C18.2348 22.7435 15.1826 24.0078 12 24.0078C8.8174 24.0078 5.76516 22.7435 3.51472 20.4931C1.26428 18.2427 0 15.1904 0 12.0078C0 8.82522 1.26428 5.77297 3.51472 3.52253ZM13.7752 5.68199C13.7871 5.39791 13.56 5.16113 13.2757 5.16113H10.9354C10.6511 5.16113 10.424 5.39791 10.4359 5.68199L10.7598 13.4408C10.771 13.7086 10.9914 13.92 11.2594 13.92H12.9517C13.2197 13.92 13.4401 13.7086 13.4513 13.4408L13.7752 5.68199ZM10.7981 18.1257C11.1517 18.4427 11.6027 18.6011 12.1513 18.6011C12.6877 18.6011 13.1265 18.4427 13.4678 18.1257C13.8214 17.7966 13.9981 17.3943 13.9981 16.9188C13.9981 16.4312 13.8214 16.0228 13.4678 15.6937C13.1265 15.3646 12.6877 15.2 12.1513 15.2C11.6027 15.2 11.1517 15.3646 10.7981 15.6937C10.4568 16.0228 10.2861 16.4312 10.2861 16.9188C10.2861 17.3943 10.4568 17.7966 10.7981 18.1257Z"
            fill="url(#paint0_linear_5964_809)"
            fillOpacity="0.8"
          />
          <defs>
            <linearGradient
              id="paint0_linear_5964_809"
              x1="4.49123"
              y1="23.2154"
              x2="23.4329"
              y2="6.87697"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.549996" stopColor="white" />
              <stop offset="0.70535" stopColor="white" stopOpacity="0.8" />
              <stop offset="1" stopColor="white" stopOpacity="0.6" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <h4 className={styles.title}>Important Disclosure</h4>
    </div>
    <div className={styles.content}>
      <p className={styles.text}>
        As a matter of important disclosure, RIO is not an investment in Realio
        or anything else, it is a native cryptocurrency and utility token for an
        open source network. Its value, if any, is purely speculative. We are
        not promoting it here or anywhere else, and we have nothing to sell to
        you. Any speculation is done on your own accord. Nothing done by Realio
        nor its team members should be understood as anything that will impact
        the price of a utility token, including our efforts to develop and
        maintain the source code. Cryptocurrencies are subject to extreme price
        volatility, and you should fully research and understand the risks of
        cryptocurrencies markets before participating in them. If you would like
        to invest in Realio, we have tokenized the company equity via a Reg D/S
        offering under the token ticker RST. Info on RST is available on{' '}
        <a
          href="https://app.realio.fund/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://app.realio.fund/
        </a>{' '}
        and whitelisting for RST is available for non-U.S. and accredited U.S.
        investors. We anticipate RST to become available for retail investors in
        the U.S. on the tZero ATS.
      </p>
    </div>
  </div>
);

export default Disclaimer;
